<template>
  <div class="btc-video-card-box hand" :class="{ active: active }" @click="handleVideo" :title="item.NewsTitle">
    <div class="img-box">
      <img :src="item.NewsTitlePic" class="video-item" />
      <img class="play" src="@/assets/image/btc-video/play.png" />
      <div v-if="item.IsTopNews && !isCn" class="free">Free</div>
      <div v-if="item.IsTopNews && isCn" class="free">Top</div>
    </div>
    <div class="text-item">
      <p  class="NewsTitle">{{ item.NewsTitle }}</p>
      <div class="time">
        <div class="btc-triangle triangle-point-right tc-1" />
        <span>{{ item.NewsDateTime }}</span>
        <img v-if="item.NewsLanguageCode ==='spanish'" style="width: 20px;height: 12px;margin-left: 50px" src="@/assets/image/btcinfo/spanish.png" :alt="item.lang" class="hand">
        <img v-if="item.NewsLanguageCode ==='portuguese'" style="width: 20px;height: 12px;margin-left: 50px" src="@/assets/image/btcinfo/portuguese.png" :alt="item.lang" class="hand">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BTCVideoCard',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    },
    isCn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  mounted () {},
  methods: {
    handleVideo () {
      this.$emit('handle-video', this.item)
    }
  }
}
</script>

<style scoped>
.btc-video-card-box {
  min-width: 284px;
  border-radius: 10px;
  padding: 8px 5px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0 0 6px 1px #e0e0e0;
}
.btc-video-card-box > .img-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btc-video-card-box > .img-box > .play {
  position: absolute;
}
.btc-video-card-box > .img-box > div:last-child {
  position: absolute;
  top: 0;
  left: 0;
}
.video-item {
  width: 110px;
  height: 60px;
  border-radius: 6px;
}
.text-item {
  min-width: 140px;
  flex: 1;
  padding: 0 10px;
  font-size: 12px;
}

.time {
  display: flex;
  align-items: center;
}

.NewsTitle{
  line-height: 18px;
  margin-bottom: 5px;
  font-size: 12px;
  width: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
}

.active {
  background: #e0e0e0;
}

.free {
  font-size: 12px;
  background: #e32229;
  border-radius: 6px 3px 3px 3px;
  color: #ffffff;
  padding: 0 5px;
}
</style>
